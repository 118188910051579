import { render } from '@wordpress/element';

import './style.scss';
import Style from './Style';

// Social Share
document.addEventListener('DOMContentLoaded', () => {
	const allSocialShare = document.querySelectorAll('.wp-block-bssb-social-share');
	allSocialShare.forEach(socialShare => {
		const attributes = JSON.parse(socialShare.dataset.attributes);

		const bssbStyle = document.querySelector(`#${socialShare.id} .bssbStyle`);

		render(<Style attributes={attributes} clientId={attributes.cId} />, bssbStyle);

		socialShare?.removeAttribute('data-attributes');
	});
});